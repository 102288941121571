import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, TextField } from '@mui/material';
import DialogIcon from 'components/_include/DialogIcon';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import RouteIcon from 'components/_include/Icons/RouteIcon';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import RoutesController from 'controllers/routes';
import { usePartnerID } from 'store/hooks';
import { CollectionType } from 'models/Collection';
import Route from 'models/Route';
import { Timestamp } from 'firebase/firestore';

type AddRouteDialogProps = {
    open: boolean;
    onClose: () => void;
    onRouteSaved?: (route: Route) => void;
}

function AddRouteDialog({ open, onClose, onRouteSaved }: AddRouteDialogProps) {
    const partnerID = usePartnerID();

    const loading = useAppSelector(state => state.routes.loading);
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.FORMS]);

    const [routeLabel, setRouteLabel] = useState("");

    const wasteStream = useAppSelector(state => state.sortingRules.selectedWasteStream);

    const handleSaveRouteClick = () => {
        if (partnerID && wasteStream) {
            dispatch(RoutesController.create(partnerID, {
                label: routeLabel,
                type: wasteStream,
                createdAt: Timestamp.now(),
            }))
                .then((route) => {
                    if (route) { // route successfully added
                        if (onRouteSaved) onRouteSaved(route);

                        onClose();
                        setRouteLabel("");
                    }
                });
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogIcon
                Icon={RouteIcon}
            />
            <DialogTitle align='center'>
                {t("onboarding.routes.add_route", { ns: Namespace.FORMS })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("onboarding.routes.description", { ns: Namespace.FORMS })}
                </DialogContentText>
                <Box mt={3}>
                    <TextField
                        margin='normal'
                        value={routeLabel}
                        onChange={(e) => setRouteLabel(e.target.value)}
                        label={t("onboarding.routes.route_label", { ns: Namespace.FORMS })}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        color: "#4F4F4F",
                    }}
                    onClick={onClose}
                >
                    {t("cancel", { ns: Namespace.ACTIONS })}
                </Button>
                <LoadingButton
                    color="success"
                    sx={{
                    }}
                    onClick={handleSaveRouteClick}
                    loading={loading}
                    disabled={routeLabel.length === 0}
                >
                    <span>{t("save", { ns: Namespace.ACTIONS })}</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default AddRouteDialog;