import { BoxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { ResponsivePie } from "@nivo/pie";
import { WasteSlice } from "components/Statistics/WasteRepartitionPieChart";
import { PIE_CHART_THEME } from "constants/theme";
import { TrashType } from "constants/trash";
import TrashTypeTooltip from "components/Statistics/TrashTypeTooltip";


type ChartProps = Pick<BoxProps, | "px"> & {
    data: WasteSlice[];
}

export default function MostFrequentErrorsPieChart({ data }: ChartProps) {

    const { t } = useTranslation([Namespace.WASTES]);

    return (
        <ResponsivePie
            data={data}
            theme={PIE_CHART_THEME}
            valueFormat={(value) => `${value}%`}
            margin={{ top: 40, right: 0, bottom: 40, left: 0 }}
            padAngle={2}
            innerRadius={0.4}
            cornerRadius={4}
            borderWidth={1}
            borderColor={{
                from: "color",
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            colors={{ datum: "data.color" }}
            arcLabelsTextColor={(d) => d.value > 5 ? "#ffffff" : "rgba(0,0,0,0.8)"}
            arcLinkLabelsStraightLength={8}
            arcLinkLabel={d => d.id === TrashType.OTHER ? t('other_small', { ns: Namespace.WASTES }) : `${d.label}`}
            startAngle={-20}
            tooltip={({ datum }) => (
                <TrashTypeTooltip
                    trashType={datum.id as TrashType}
                    label={datum.data.label}
                    value={datum.formattedValue}
                />
            )}
        />
    );
}