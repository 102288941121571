import Collection, { CollectionDbData, CollectionType } from "models/Collection";
import store, { AppDispatch } from "store/store";
import { DbOrder, QueryFilter } from "constants/types";
import { CollectionsActions } from "store/reducers/collections/list";
import { formatQuery, getCollectionGroupRef, getCollectionRef, getDocumentReference, listDocs } from "helpers/db";
import { DbCollection, NextQuery } from "constants/db";
import { showError, showTranslatedMessage } from "store/reducers/snacks";
import { QueryDocumentSnapshot, DocumentData, DocumentSnapshot, updateDoc, getDocs, writeBatch, Timestamp, getFirestore } from "firebase/firestore";
import { SelectedCollectionActions } from "store/reducers/collections/selected";
import { BatchesMapActions } from "store/reducers/batches/map";
import { BatchesActions } from "store/reducers/batches/list";
import { SavedAddressesActions } from "store/reducers/saved_addresses/list";
import { chunk } from "lodash";
import { CollectionRFIDActions } from "store/reducers/collections/rfid";
import { fetchAPI, handleAPIError } from "./actions";
import moment from "moment";
import { API_TIMESTAMP_FORMAT } from "constants/dates";
import urls from "constants/urls";
import BatchesController from "./batches";

function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): Collection;
function fromDbDoc(dbDoc: DocumentSnapshot<DocumentData>): Collection | null;
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>) {
    const data = dbDoc.data() as CollectionDbData;

    const truckRef = dbDoc.ref.parent.parent!;

    const collectionData: Collection = {
        ...data,
        ID: dbDoc.id,
        truckID: truckRef.id,
        startAt: data.startAt.toMillis(),
        endAt: data.endAt?.toMillis(),
        results: data.results,
        surfaces: data.surfaces,
        loading: false,
    }
    return collectionData;
}

const listAll = (partnerID: string, filters: QueryFilter<CollectionDbData>[], order: DbOrder<CollectionDbData>, limit: number) => async (dispatch: AppDispatch) => {
    dispatch(CollectionsActions.startLoadingList());

    const extendedFilters: QueryFilter<CollectionDbData>[] = [
        { fieldPath: "partnerID", opStr: "==", value: partnerID },
        ...filters,
    ];

    const collectionGroupRef = getCollectionGroupRef(DbCollection.COLLECTIONS);
    const query = formatQuery(collectionGroupRef, extendedFilters, order, limit);

    try {
        const snapshot = await getDocs(query.query);
        const collections = snapshot.docs.map(doc => fromDbDoc(doc));

        // save next query for "load more"
        let nextQuery: NextQuery<CollectionDbData> | null = null;
        if (limit && collections.length === limit) { // there may be more batches to load
            nextQuery = {
                isCollectionGroup: true,
                collectionPath: DbCollection.COLLECTIONS,
                startAfterValue: collections[limit - 1][order.fieldPath],
                filters: extendedFilters,
                order,
                limit,
            };
        }

        dispatch(CollectionsActions.setList({ collections, nextQuery }));

        // select latest processed collection by default
        if (collections.length > 0 && !store.getState().collections.selected.data) {
            const latestCollection = collections.filter(c => c.processed)[0];
            if (latestCollection) dispatch(SelectedCollectionActions.setData(latestCollection));
        }
        return collections;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to load collections", error);
        dispatch(showError(error.message));
        dispatch(CollectionsActions.setError(error.message));
        return [];
    }
};

const loadMore = () => async (dispatch: AppDispatch) => {
    const savedQuery = store.getState().collections.list.nextQuery;
    if (!savedQuery) return [];

    dispatch(CollectionsActions.startLoadingMore());

    let { collectionPath, isCollectionGroup, filters, order, limit, startAfterValue, } = savedQuery;
    if (order.fieldPath === "startAt") startAfterValue = Timestamp.fromMillis(startAfterValue);

    const collectionRef = isCollectionGroup ? getCollectionGroupRef(collectionPath as DbCollection) : getCollectionRef(collectionPath.split("/"));

    const query = formatQuery(collectionRef, filters, order, limit, startAfterValue);

    try {
        const snapshot = await getDocs(query.query);
        const collections = snapshot.docs.map(doc => fromDbDoc(doc));

        // save next query for "load more"
        let nextQuery: NextQuery<CollectionDbData> | null = null;
        if (collections.length === limit) { // there may be more batches to load
            nextQuery = {
                ...savedQuery,
                startAfterValue: collections[limit - 1][order.fieldPath],
            };
        }

        dispatch(CollectionsActions.addMany({ collections, nextQuery }));

        return collections;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to load more collections", error);
        dispatch(showError(error.message));
        dispatch(CollectionsActions.setError(error.message));
        return [];
    }
};

const list = (partnerID: string, truckID: string, filters: QueryFilter<CollectionDbData>[], order: DbOrder<CollectionDbData>, limit: number) => async (dispatch: AppDispatch) => {
    dispatch(CollectionsActions.startLoadingList());

    const collectionPath = [DbCollection.PARTNERS, partnerID, DbCollection.TRUCKS, truckID, DbCollection.COLLECTIONS];

    const collectionRef = getCollectionRef(collectionPath);
    const query = formatQuery(collectionRef, filters, order, limit);

    try {
        const collectionsSnapshot = await getDocs(query.query);
        const collections = collectionsSnapshot.docs.map(doc => fromDbDoc(doc));

        // save next query for "load more"
        let nextQuery: NextQuery<CollectionDbData> | null = null;
        if (limit && collections.length === limit) { // there may be more batches to load
            nextQuery = {
                isCollectionGroup: false,
                collectionPath: collectionPath.join('/'),
                startAfterValue: collections[limit - 1][order.fieldPath],
                filters: filters,
                order,
                limit,
            };
        }

        dispatch(CollectionsActions.setList({ collections, nextQuery }));
        return collections;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to load collections", error);
        dispatch(showError(error.message));
        dispatch(CollectionsActions.setError(error.message));
        return [];
    }
};

const retrieve = (partnerID: string, truckID: string, filters: QueryFilter<CollectionDbData>[]) => async (dispatch: AppDispatch) => {
    dispatch(SelectedCollectionActions.startLoading());

    const collectionPath = [DbCollection.PARTNERS, partnerID, DbCollection.TRUCKS, truckID, DbCollection.COLLECTIONS];

    try {
        const collectionsDocs = await listDocs(collectionPath, filters, { fieldPath: "startAt" }, 1);
        // there may not be any collection matching filters
        const collection = collectionsDocs.length > 0 ? fromDbDoc(collectionsDocs[0]) : null;
        dispatch(SelectedCollectionActions.setData(collection));
        return collection;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to load collection", error);
        dispatch(showError(error.message));
        dispatch(SelectedCollectionActions.setError(error.message));
        return [];
    }
};

const update = (collection: Collection, data: Partial<CollectionDbData>) => async (dispatch: AppDispatch) => {
    dispatch(CollectionsActions.updateItem({ ID: collection.ID, data: { loading: true } }));

    const parentPath = `${DbCollection.PARTNERS}/${collection.partnerID}/${DbCollection.TRUCKS}/${collection.truckID}`;
    const docRef = getDocumentReference(collection.ID, DbCollection.COLLECTIONS, parentPath);
    try {
        await updateDoc(docRef, data);
        dispatch(CollectionsActions.updateItem({
            ID: collection.ID, data: {
                ...data,
                startAt: data.startAt ? data.startAt.toMillis() : collection.startAt,
                endAt: data.endAt ? data.endAt.toMillis() : collection.endAt,
                loading: false,
            }
        }));
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to update collection", error);
        dispatch(showError(error.message));
        dispatch(CollectionsActions.setError(error.message));
    }
};

const changeCollectionType = (collection: Collection, newType: CollectionType) => async (dispatch: AppDispatch) => {
    dispatch(CollectionsActions.updateItem({ ID: collection.ID, data: { loading: true } }));

    const parentPath = `${DbCollection.PARTNERS}/${collection.partnerID}/${DbCollection.TRUCKS}/${collection.truckID}`;
    const docRef = getDocumentReference(collection.ID, DbCollection.COLLECTIONS, parentPath);
    try {
        await updateDoc(docRef, { type: newType });

        if (collection.type !== newType) {
            // unselect and remove collection 
            dispatch(SelectedCollectionActions.setData(null));
            dispatch(BatchesMapActions.removeMapPoints());
            dispatch(BatchesActions.resetList());
            dispatch(SavedAddressesActions.setList([]));
            dispatch(CollectionsActions.removeItem(collection.ID));
        }
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to change collection type", error);
        dispatch(showError(error.message));
        dispatch(CollectionsActions.setError(error.message));
    }
};

/**
 * Mark all of the partner's collections as Household waste collections.
 * This method is used to clear the partner's dashboard before sharing it with the client.
 */
const markAllAsHousehold = (partnerID: string, partnerName: string) => async (dispatch: AppDispatch) => {
    dispatch(CollectionsActions.startLoadingList());

    try {
        // list all of the sortable waste collections from this partner (may be from different trucks)
        const allCollectionsDocs = await listDocs(
            [DbCollection.COLLECTIONS],
            [
                { fieldPath: "partnerID", opStr: "==", value: partnerID },
                { fieldPath: "type", opStr: "==", value: CollectionType.SORTABLE_WASTE },
            ],
            undefined,
            undefined,
            true,
        );

        const collectionsDocsChunks = chunk(allCollectionsDocs, 500); // limit of 500 writes per batch operation

        for (let docsChunk of collectionsDocsChunks) {
            // hide all of the collections using batch writes (500 at a time)
            const batchWrite = writeBatch(getFirestore());

            for (let collectionDoc of docsChunk) {
                batchWrite.update(collectionDoc.ref, { type: CollectionType.HOUSEHOLD_WASTE });
            }

            await batchWrite.commit();
        }

        // clear previous loaded list of collections
        dispatch(CollectionsActions.setList({ collections: [], nextQuery: null }));

        // show success alert
        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "collections.hide_all.success",
            context: {
                partner: partnerName,
            },
        }));

        return true;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to hide all collections", error);
        dispatch(showError(error.message));
        dispatch(CollectionsActions.setError(error.message));

        return false;
    }
}

/**
 * Upload the list of RFID numbers and their timestamps to the list of batches for a Collection.
 */
const setRFID = (collection: Collection, selectedDatetimeColumns: string, selectedRFIDColumns: string, datetimeFormat: string) => async (dispatch: AppDispatch) => {
    const { data: excelData } = store.getState().collections.rfid;
    if (!excelData) return false;

    dispatch(CollectionRFIDActions.startLoading());

    // convert datetimes strings to correct format
    const data = excelData.map(row => ({
        timestamp: moment(row[selectedDatetimeColumns], datetimeFormat).format(API_TIMESTAMP_FORMAT),
        rfid: row[selectedRFIDColumns]
    }));

    // sort by datetime ascending
    data.sort((lift1, lift2) => moment(lift1.timestamp).isBefore(moment(lift2.timestamp)) ? -1 : 1);
    
    try {
        await fetchAPI(`${urls.API}/partner/${collection.partnerID}/truck/${collection.truckID}/collection/${collection.ID}/rfid`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        // show success alert
        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "upload_rfid.success"
        }));

        // hide dialog
        dispatch(CollectionRFIDActions.cleanUp());

        // reload the list of batches
        dispatch(BatchesController.listPartnerBatchesForMap(
            collection.partnerID,
            [
                { fieldPath: "collectionID", opStr: "==", value: collection.ID, },
                { fieldPath: "display", opStr: "==", value: true, },
                { fieldPath: "verified", opStr: "==", value: true, }
            ],
            [],
            "collection",
        ));

        return true;
    }
    catch (e) {
        dispatch(handleAPIError(e, "to set collection RFIDs", CollectionRFIDActions.setError));
        return false;
    }
};

const select = (collection: Collection) => (dispatch: AppDispatch) => {
    dispatch(SelectedCollectionActions.setData(collection));
    dispatch(BatchesMapActions.removeMapPoints());
    dispatch(BatchesActions.resetList());
    dispatch(SavedAddressesActions.setList([]));
};

const CollectionsController = {
    list,
    listAll,
    loadMore,
    retrieve,
    update,
    changeCollectionType,
    markAllAsHousehold,
    setRFID,
    select,
};

export default CollectionsController;