import { amber, blueGrey, brown, cyan, deepOrange, deepPurple, green, indigo, lime, orange, pink, purple, red, teal, yellow } from "@mui/material/colors";
import { CollectionType } from "models/Collection";

/**
 * Different classes of waste classified by Ficha
 */
export enum TrashType {
    RECYCLE_WASTE = 'recycleWaste',
    BIG_CARDBOARD = 'bigCardboard',
    BULKY = 'bulky',
    CABLE = 'cable',
    CERAMIC = 'ceramic',
    CHEMICAL = 'chemical',
    ELECTRONIC_WASTE = 'electronicWaste',
    GARBAGE_BAG = 'garbageBag',
    GAS_CYLINDER = 'gasCylinder',
    GLASS_BOTTLE = 'glassBottle',
    GLASS_JAR = 'glassJar',
    GREEN_WASTE = 'greenWaste',
    ORGANIC_WASTE = 'organicWaste',
    POLYSTYRENE= 'polystyrene',
    SURGICAL_MASK = 'surgicalMask',
    TEXTILE = 'textile',
    UNDESIRABLE_FIBROUS = 'undesirableFibrous',
    OTHER = 'other',
};

/**
 * Object with waste classes as keys and numbers as values
 */
export type TrashCount = {
    [key in TrashType]: number;
};

export type SortingRules = {
    [trashType in TrashType]: CollectionType[];
};

/**
 * Default waste collections for each class of waste.
 * For example, big cardboards are by default collected in sortable waste collections.
 */
export const DEFAULT_SORTING_ERRORS: SortingRules = {
    [TrashType.RECYCLE_WASTE]: [CollectionType.SORTABLE_WASTE],
    [TrashType.BIG_CARDBOARD]: [CollectionType.SORTABLE_WASTE],
    [TrashType.BULKY]: [CollectionType.RESIDUAL_WASTE],
    [TrashType.CABLE]: [CollectionType.RESIDUAL_WASTE],
    [TrashType.CERAMIC]: [CollectionType.RESIDUAL_WASTE],
    [TrashType.CHEMICAL]: [CollectionType.RESIDUAL_WASTE],
    [TrashType.ELECTRONIC_WASTE]: [CollectionType.RESIDUAL_WASTE],
    [TrashType.GARBAGE_BAG]: [CollectionType.RESIDUAL_WASTE],
    [TrashType.GAS_CYLINDER]: [CollectionType.RESIDUAL_WASTE],
    [TrashType.GLASS_BOTTLE]: [CollectionType.GLASS_WASTE],
    [TrashType.GLASS_JAR]: [CollectionType.GLASS_WASTE],
    [TrashType.GREEN_WASTE]: [CollectionType.BIOWASTE],
    [TrashType.ORGANIC_WASTE]: [CollectionType.BIOWASTE],
    [TrashType.OTHER]: [CollectionType.RESIDUAL_WASTE],
    [TrashType.POLYSTYRENE]: [CollectionType.RESIDUAL_WASTE],
    [TrashType.SURGICAL_MASK]: [CollectionType.RESIDUAL_WASTE],
    [TrashType.TEXTILE]: [CollectionType.RESIDUAL_WASTE],
    [TrashType.UNDESIRABLE_FIBROUS]: [CollectionType.RESIDUAL_WASTE],
}

/** Object indicating in which waste class each subclass should go. */
export type MergingWasteMapping = Partial<Record<TrashType, TrashType>>;

/**
 * For some subclasses of waste, the AI is not mature enough to trust its results.
 * Therefore we want to merge its results into some larger classes.
 *
 * List of default re-mapping:
 *  - consider big cardboard as recycle waste
 *  - consider cable, ceramic, chemical as others
 *  - consider polystyrene as bulky
 *  - consider glass jars as glass bottles ("glass")
 *  - consider surgical masks, undesirable fibrous as textiles
 */

export const DEFAULT_MERGING_WASTE_MAPPING: Partial<Record<TrashType, TrashType>> = {
    [TrashType.BIG_CARDBOARD]: TrashType.RECYCLE_WASTE,
    [TrashType.CABLE]: TrashType.OTHER,
    [TrashType.CERAMIC]: TrashType.OTHER,
    [TrashType.CHEMICAL]: TrashType.OTHER,
    [TrashType.POLYSTYRENE]: TrashType.BULKY,
    [TrashType.GLASS_JAR]: TrashType.GLASS_BOTTLE,
    [TrashType.SURGICAL_MASK]: TrashType.TEXTILE,
    [TrashType.UNDESIRABLE_FIBROUS]: TrashType.TEXTILE,
};

/**
 * List of the most commonly encountered classes of waste.
 * This list is:
 * - Recyclable waste
 * - Big cardboards
 * - Garbage bags
 * - Others
 * - Textiles
 * - Bulky items
 */
export const MOST_COMMON_TRASH_TYPES = [TrashType.GARBAGE_BAG, TrashType.OTHER, TrashType.TEXTILE, TrashType.BULKY, TrashType.RECYCLE_WASTE, TrashType.BIG_CARDBOARD,];

/**
 * List of the less commonly encountered classes of waste.
 * This list includes:
 * - Electronic waste
 * - Glass bottle
 * - Organic waste
 * - ...
 */
export const LESS_COMMON_ERRORS = Object.values(TrashType).filter(type => !MOST_COMMON_TRASH_TYPES.includes(type));

/**
 * Emojis representing visually the different types of waste
 */
export const TRASH_TYPES_ICONS = {
    [TrashType.RECYCLE_WASTE]: '♻️',
    [TrashType.BIG_CARDBOARD]: '📦',
    [TrashType.BULKY]: '🛋️',
    [TrashType.CABLE]: '🪢',
    [TrashType.CERAMIC]: '🏺',
    [TrashType.CHEMICAL]: '🧪',
    [TrashType.ELECTRONIC_WASTE]: '📟',
    [TrashType.GARBAGE_BAG]: '⚫️',
    [TrashType.GAS_CYLINDER]: '🧯',
    [TrashType.GLASS_BOTTLE]: '🍾',        
    [TrashType.GLASS_JAR]: '🫙',
    [TrashType.GREEN_WASTE]: '🌿',
    [TrashType.POLYSTYRENE]: '🌫️',
    [TrashType.ORGANIC_WASTE]: '🍔',
    [TrashType.SURGICAL_MASK]: '😷',
    [TrashType.TEXTILE]: '👕',
    [TrashType.UNDESIRABLE_FIBROUS]: '🧻',
    [TrashType.OTHER]: '🗑️',
};

type TrashColorMap = {
    [key in TrashType]: string;
} 

/**
 * Colors for the types of trash displayed to the normal users
 */
export const WASTES_COLORS: TrashColorMap = {
    [TrashType.RECYCLE_WASTE]: "#336699",
    [TrashType.BIG_CARDBOARD]: indigo[500],
    [TrashType.BULKY]: blueGrey[500],
    [TrashType.CABLE]: cyan[500],
    [TrashType.CERAMIC]: teal[500],
    [TrashType.CHEMICAL]: yellow[500],
    [TrashType.ELECTRONIC_WASTE]: amber[500],
    [TrashType.GARBAGE_BAG]: "#55AAFF",
    [TrashType.GAS_CYLINDER]: purple[500],
    [TrashType.GLASS_BOTTLE]: pink[500],
    [TrashType.GLASS_JAR]: red[500],
    [TrashType.GREEN_WASTE]: green[500],
    [TrashType.ORGANIC_WASTE]: brown[500],
    [TrashType.POLYSTYRENE]: "#ffffff",
    [TrashType.SURGICAL_MASK]: lime[500],
    [TrashType.TEXTILE]: orange[500],
    [TrashType.UNDESIRABLE_FIBROUS]: deepOrange[500],
    [TrashType.OTHER]: deepPurple[500],
};

/**
 * Colors for the types of trash displayed to the validators.
 * These differ from the normal users to match the CVAT colors (software used for annotation),
 * and we don't care about the colors palette 
 */
export const VALIDATION_WASTES_COLORS: TrashColorMap = {
    [TrashType.RECYCLE_WASTE]: "#32B7FA",//
    [TrashType.BIG_CARDBOARD]: "#FFCC33", //
    [TrashType.BULKY]: "#B83DF5", //
    [TrashType.CABLE]: "#FAFA37", //
    [TrashType.CERAMIC]: teal[500],
    [TrashType.CHEMICAL]: "#FF00CC", //
    [TrashType.ELECTRONIC_WASTE]: amber[500],
    [TrashType.GARBAGE_BAG]: "#FA3253",//
    [TrashType.GAS_CYLINDER]: purple[500],
    [TrashType.GLASS_BOTTLE]: "#3D3DF5", //
    [TrashType.GLASS_JAR]: red[500],
    [TrashType.GREEN_WASTE]: "#24B353", //
    [TrashType.ORGANIC_WASTE]: brown[500],
    [TrashType.POLYSTYRENE]: "#ffffff",
    [TrashType.SURGICAL_MASK]: lime[500],
    [TrashType.TEXTILE]: "#AAF0D1", //
    [TrashType.UNDESIRABLE_FIBROUS]: deepOrange[500],
    [TrashType.OTHER]: "#733380", //
};

